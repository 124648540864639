.success-page-main-row {
  min-height: calc(100vh - 160px);
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  button,
  div {
    width: 100%;
    max-width: 400px;
  }

  @media screen and (max-height: 700px) {
    .primary-text-success {
      font-size: 1.25rem;
      margin-bottom: 0.25rem;
    }

    p {
      font-size: 0.9rem;
      margin: 0.25rem 0;
    }
  }
}

span.button-skeleton {
  font-size: 3.4rem;
  width: 100%;
  border-radius: 11px;
}

.feedback-button {
  width: 100%;
  height: 40px;
  margin-bottom: 24px;

  svg.MuiSvgIcon-root {
    margin-left: 4px;
    height: 0.9em;
    width: 0.9em;
  }
}

button.extend-stay-button {
  margin-top: 1rem;
}

button.submit-extend-stay,
button.submit-extend-stay:hover {
  margin-top: 8px;
  margin-bottom: 12px;
}

.next-end-time {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
}

p.time-error {
  color: #ff270a;
}

h6.error-message {
  font-size: 12px;
  margin-top: 5px;
  color: #ff0000;
}

button.cancel-button {
  border-color: #ffffff3b;

  &button.MuiButton-root.gradient-button,
  &.MuiButton-root[disabled],
  &.MuiButton-root[disabled]:hover {
    background: unset;
  }
}

button.mt-10 {
  margin-top: 12px;
}

.extend-stay-area {
  z-index: 9999999999;
  background: #0d0533;
  padding-bottom: 1rem;
  position: relative;
  margin-bottom: 6rem;
}

div.max-date-error {
  margin-top: 1rem;
  text-align: left;
  background: #0d0533;
  z-index: 9999999999;
  position: relative;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #ffffff3b;

  div.MuiAlert-icon {
    padding: 0;

    svg.MuiSvgIcon-root {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  div.MuiAlertTitle-root {
    font-weight: 600;
  }
}

button.mt-15 {
  margin-top: 1rem;
}

div.extend-stay-summary {
  gap: 24px;
  flex-direction: column;
  display: inline-flex;
  max-width: 350px;
}

@media screen and (max-height: 600px) {
  div.extend-stay-summary {
    gap: 12px;
  }
}

@media screen and (min-height: 600px) and (max-height: 700px) {
  div.extend-stay-summary {
    gap: 12px;
  }
}

@media screen and (min-height: 700px) {
  div.extend-stay-summary {
    gap: 15px;
  }
}

@media screen and (min-height: 780px) {
  div.extend-stay-summary {
    gap: 20px;
  }
}

@media screen and (min-height: 800px) {
  div.extend-stay-summary {
    gap: 24px;
  }
}

.license-edit-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.license-edit {
  display: flex;
  button.MuiButton-root {
    margin-left: 6px;
    background-color: #125bfb;
    padding: 8px;
  }
}

div.payment-error {
  svg.MuiSvgIcon-root.error-icon {
    height: 6rem;
    width: 6rem;
    color: #ff270a;
  }

  h1 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 3rem;
  }
}

div.license-edit-dialog {
  div.MuiDialog-paper {
    background-color: #0d0533;
    background-image: unset;
  }

  p {
    margin-bottom: 1rem;
  }

  div.action {
    text-align: right;
    margin-top: 1rem;

    button.gradient-button {
      max-width: 150px;
    }
  }
}

.small-dimmed-text {
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

div.grid-space {
  gap: 20px;
  flex-direction: column;
  display: inline-flex;
  max-width: 350px;

  @media screen and (max-height: 700px) {
    gap: 12px;
  }
}

.post-payment-instructions {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  margin: 5px 0 16px;
  white-space: pre-line;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.success-page-email-text {
  font-size: 1.1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
